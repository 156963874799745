.navbar {
    width: 100%;
    background-color: #fff;
    padding: 10px 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
}

.navbar-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    width: 80%;
}

.navbar-logo {
    height: 50px;
}

.brand{
    display: flex;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    padding: 0 20px;
    border-radius: 10px;
    border: 1px solid #BDBDBD;
}

.australia-img {
    height: 30px;
    margin-right: 10px;
}

.brand-text{
    line-height: 1;
}

.navbar-toggle {
    display: none;
    cursor: pointer;
}

.navbar-toggle-icon {
    font-size: 24px;
}

.navbar-menu {
    display: flex;
    list-style: none;
}

.navbar-item {
    margin-left: 2.5vw;
}

.navbar-link {
    text-decoration: none;
    color: #000;
    font-size: 16px;
    transition: color 0.3s;
    cursor: pointer;
}

.navbar-link:hover {
    color: #007BFF;
}

/* Mobile view */
@media (max-width: 768px) {
    .navbar{
        padding: 0;
    }
    .navbar-container{
        width: 94%;
    }

    .brand{
        padding: 0 4px;
    }
    .navbar-toggle {
        display: block;
    }

    .navbar-menu {
        display: none;
        flex-direction: column;
        position: absolute;
        top: 60px;
        right: 50px;
        width: 40%;
        background-color: #fff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    .navbar-menu.mobile {
        display: flex;
    }

    .navbar-item {
        margin: 10px 0;
        text-align: center;
    }
}
