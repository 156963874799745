.values-container {
  display: flex;
  flex-wrap: wrap;
  /* flex-direction: row-reverse; */
  justify-content: center;
  margin: 80px auto;
}

.value-card {
  border-radius: 2px;
  border: #BDBDBD 1px solid;
  padding: 20px 30px;
  margin: 10px;
  width: 15%;
  max-width: 300px;
  text-align: left;
  transform: translateY(30px);
  transition: opacity 1s ease-out, transform 1s ease-out;
  opacity: 0;  /* Initially hidden */
}

.value-card.visible {
  transform: translateY(0);
  opacity: 1;  /* Fade in when visible */
}

.icon-container {
  padding: 12px 18px;
  background-color: #E8E8E8;
  width: fit-content;
  height: fit-content;
  border-radius: 15px;
}

.icon-img {
  width: 30px;
  height: 30px;
}

.value-title {
  font-size: 22px;
  margin: 8px 0;
}

.value-description {
  font-size: 16px;
  color: #666;
}

/* Responsive Design */
@media (max-width: 768px) {
  .value-card {
    width: 30%;
    max-width: none;
  }
  .value-title {
    font-size: 18px;
    margin: 8px 0;
  }
  
  .value-description {
    font-size: 14px;
    color: #666;
  }
}

@media (max-width: 480px) {
  .value-card {
    width: 50%;
  }
  .value-title {
    font-size: 18px;
    margin: 8px 0;
  }
  
  .value-description {
    font-size: 14px;
    color: #666;
  }
}
